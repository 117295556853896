import { create } from 'zustand'
export const useMain = create((set) => ({
  isLoaded: false,
  pointerLocked: false,
  connected: false,
  gravity:  [0,-9.8,0],
  lockPointer: () => set((state) => ({pointerLocked: true})),
  unlockPointer: () => set((state) => ({pointerLocked: false})),
  togglePhysicsDebugging: () => set((state) => ({debuggingPhysics: !state.debuggingPhysics})),
  setGravity: (x, y, z) => set((state) => ({gravity: [x,y,z]}))
}))