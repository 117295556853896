import React from 'react';
import Placeholder from './Placeholder';
import { usePlayersStore } from '../stores/playersStore';
const Placeholders = () => {
  const { players } = usePlayersStore((state) => state);
  function processItems () {
    const placeholders = [];
    if(players && !players.includes(undefined)) {
      players.forEach(p=> {
        placeholders.push(<Placeholder key={p.i} position={[
          p.x,
          p.y + 0.30,
          p.z
        ]} name={p.i}/>);
      })
    }
    return placeholders
  }
  return (  
    <>
      {processItems()}
    </>
  );
}

export default Placeholders;