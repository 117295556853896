import { usePlane } from "@react-three/cannon";
export const Plane = () => {
  const [ref] = usePlane(() => ({
    rotation: [-Math.PI / 2, 0, 0],
    position: [0, -0.25, 0],
    args: [1000,1000],
    material: {
      friction: 0.1
    }
  }))

  return (
    <mesh ref={ref} layers={2} receiveShadow={true} scale={[10000, 10000, 1]}>
      <planeGeometry />
    </mesh>
  )
}
