/* eslint-disable no-unused-vars */
import { useSphere } from "@react-three/cannon";
import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import { Raycaster, Vector3 } from "three";
import { useMain } from "../stores/useMain";
import usePlayerController from "../stores/usePlayerController";
import { useUserInput } from "../stores/useUserInput";
import Sockets from "../sockets/socket"

export const PlayerController = ({position}) => {
  const [initialized, setInitialized] = useState(false)
  const { pointerLocked } = useMain()
  const { forward, back, left, right, run, jump } = useUserInput()
  const { speed, runSpeed, jumpHeight, mass } = usePlayerController()
  const { camera, scene } = useThree()
  const state = useRef({
    vel: [0, 0, 0],
    timeTojump: 0,
    jumping: false,
    grounded: false
  });
  const [ sphereRef, api ] = useSphere(() => ({
    mass,
    fixedRotation: true,
    position,
    args: [0.5, 0.5],
    material: {
      friction: 0
    }
  }));
  const [groundedRef, groundedApi] = useSphere(() => ({
    type: "Kinematic",
    position,
    collisionResponse: false,
    onCollideBegin: (e) => {
      if(e.body.name !== "Player") {
        state.current.grounded = true
      }
    },
    onCollideEnd: (e) => {
      if(e.body.name !== "Player") {
        state.current.grounded = false
      }
    },
    args: [0.1, 0.1],
  }));

  useEffect(() => {
    if(!initialized) {
      api.velocity.subscribe((v) => {
        state.current.vel = v
      });
      api.position.subscribe((pos)=>{
        // console.log(pos)
        groundedApi.position.set(pos[0], pos[1] - 0.5, pos[2])
        camera.position.set(pos[0], pos[1] + 0.4, pos[2])
        const shouldUpdate = () => {
          const {x,y,z} = usePlayerController.getState()
          const pos2 = [x,y,z]
          return pos2.find((p, i) => Math.abs(p - pos[i]) > 0.001) !== undefined
        }
        if(shouldUpdate()) {
          usePlayerController.setState({
            x: pos[0],
            y: pos[1],
            z: pos[2]
          })
          Sockets.update(new Vector3(pos[0], pos[1], pos[2]))
        }
      })
      setInitialized(true)
    }
  }, [api, camera.position, initialized, groundedApi.position]);

  useFrame(()=>{
    if(pointerLocked) {
      let velocity = new Vector3(0, 0, 0);
      let cameraDirection = new Vector3();
      camera.getWorldDirection(cameraDirection);
      let Forward = new Vector3();
      Forward.setFromMatrixColumn(camera.matrix, 0)
      Forward.crossVectors(camera.up, Forward)
      let Right = new Vector3()
      Right.setFromMatrixColumn(camera.matrix, 0)
      let [horizontal, vertical] = [0, 0];
  
      if (forward) {
        vertical += 1;
      }
      if (back) {
        vertical -= 1;
      }
      if (right) {
        horizontal += 1;
      }
      if (left) {
        horizontal -= 1;
      }

      const Speed = run ? runSpeed : speed

      if (horizontal !== 0 && vertical !== 0) {
        velocity
          .add(Forward.clone().multiplyScalar(Speed * vertical))
          .add(Right.clone().multiplyScalar(Speed * horizontal));
        velocity.clampLength(-5, 5);
      } else if (horizontal !== 0) {
        velocity.add(Right.clone().multiplyScalar(Speed * horizontal));
      } else if (vertical !== 0) {
        velocity.add(Forward.clone().multiplyScalar(Speed * vertical));
      }
  
      api.velocity.set(velocity.x, state.current.vel[1], velocity.z);
      if (jump && state.current.grounded) {
        api.velocity.set(state.current.vel[0], jumpHeight, state.current.vel[2]);
      }
    }
  })
  return (
    <group>
      <mesh ref={sphereRef} name="Player"/>
      <mesh ref={groundedRef}/>
    </group>
  )
}