import { create } from 'zustand'
function shouldShowDebugMenu() {
  let a = process.env.REACT_APP_SHOW_DEBUG_MENU
  return a && a.toLowerCase() === 'yes'
}
export const useDebug = create((set) => ({
  showDebugMenu: false,
  showStats: false,
  showPhysicsWireframes: false,
  showKeyboard: false,
  showController: false,
  toggleStats: () => set((state) => ({showStats: !state.showStats})),
  togglePhysicsMenu: () => set((state) => ({physicsMenuExpanded: !state.physicsMenuExpanded})),
  togglePhysicsWireframes: () => set((state) => ({showPhysicsWireframes: !state.showPhysicsWireframes})),
  toggleShowKeyboard: () => set((state) => ({showKeyboard: !state.showKeyboard})),
  toggleShowController: () => set((state) => ({showController: !state.showController})),
}))