import { useRef, useEffect, useState } from "react"
import { useUserInput } from "../../stores/useUserInput"
import "../../style/css/debugKeyboard.css"
const DebugKeyboard = () => {
  const ref = useRef()
  const {
    mouseX,
    mouseY,
    forward,
    back,
    left,
    right,
    run,
    jump,
    crouch,
    interactAction,
    openSettingsAction
  } = useUserInput()
  const key = {
    W: forward ? 'int key pressed' : 'int key',
    S: back ? 'int key pressed' : 'int key',
    A: left ? 'int key pressed' : 'int key',
    D: right ? 'int key pressed' : 'int key',
    E: interactAction ? 'int key pressed' : 'int key',
    C: crouch ? 'int key pressed' : 'int key',
    Esc: openSettingsAction ? 'int key pressed' : 'int key',
    Shift: run ? 'int key leftshift pressed' : 'int key leftshift',
    Space: jump ? 'int key space pressed' : 'int key space',
  }
  const [attached, setAttached] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [X, setX] = useState(null)
  const [Y, setY] = useState(null)
  useEffect(()=> {
    if(!attached) {
      const domElem = ref.current
      domElem.addEventListener("mousedown", (e)=>{
        if(e.which === 1) {
          setDragging(true)
          setX(e.clientX)
          setY(e.clientY)
        }
      })
      domElem.addEventListener("mouseup", (e)=>{
        if(e.which === 1) {
          setDragging(false)
        }
      })
      setAttached(true)
    }
  },[attached])
  useEffect(()=>{
    if(dragging) {
      const domElem = ref.current
      domElem.style.top = `${mouseY}px`;
      domElem.style.bottom = `initial`;
      domElem.style.left = `${mouseX}px`;
    }
  }, [dragging, mouseX, mouseY, X, Y])
  return (
    <div ref={ref} className="keyboard-base">
      <div className={key.Esc}>Esc</div>
      <div className="key">~</div>
      <div className="key">1</div>
      <div className="key">2</div>
      <div className="key">3</div>
      <div className="key">4</div>
      <div className="key">5</div>
      <div className="key">6</div>
      <div className="key">7</div>
      <div className="key">8</div>
      <div className="key">9</div>
      <div className="key">0</div>
      <div className="key">-</div>
      <div className="key">+</div>
      <div className="key delete">Del</div>
      <div className="key tab">Tab</div>
      <div className="key">Q</div>
      <div className={key.W}>W</div>
      <div className={key.E}>E</div>
      <div className="key">R</div>
      <div className="key">T</div>
      <div className="key">Y</div>
      <div className="key">U</div>
      <div className="key">I</div>
      <div className="key">O</div>
      <div className="key">P</div>
      <div className="key">[</div>
      <div className="key">]</div>
      <div className="key backslash">\</div>
      <div className="key capslock">Caps</div>
      <div className={key.A}>A</div>
      <div className={key.S}>S</div>
      <div className={key.D}>D</div>
      <div className="key">F</div>
      <div className="key">G</div>
      <div className="key">H</div>
      <div className="key">J</div>
      <div className="key">K</div>
      <div className="key">L</div>
      <div className="key">;</div>
      <div className="key">'</div>
      <div className="key return">Enter</div>
      <div className={key.Shift}>Shift</div>
      <div className="key">Z</div>
      <div className="key">X</div>
      <div className={key.C}>C</div>
      <div className="key">V</div>
      <div className="key">B</div>
      <div className="key">N</div>
      <div className="key">M</div>
      <div className="key">,</div>
      <div className="key">.</div>
      <div className="key">/</div>
      <div className="key rightshift">Shift</div>
      <div className="key leftctrl">Ctrl</div>
      <div className="key">Alt</div>
      <div className="key command">CMD</div>
      <div className={key.Space}>Space</div>
      <div className="key command">CMD</div>
      <div className="key">Alt</div>
      <div className="key rightctrl">Ctrl</div>
    </div>
  )
}
export default DebugKeyboard