import { io } from "socket.io-client"
import { useMessageStore } from "../stores/messagesStore"
import { usePlayersStore } from "../stores/playersStore"
const socket = io("https://democity.lydian.world:8443")
window.io = io

const internal = {
  sendMessage: (m)=> {
    socket.emit('messagesent', {
      type: 'local',
      message: m
    })
  },
}

const { setPlayers, addPlayer, updatePlayer ,removePlayer } = usePlayersStore.getState()

socket.on('players', msg => {
  setPlayers(msg)
})

socket.on('joined', msg => {
  addPlayer(msg)
})

socket.on('update', msg => {
  updatePlayer(msg)
})

socket.on('disconnected', msg => {
  removePlayer(msg)
})
socket.on('messagereceived', msg=> {
  const { addMessage } = useMessageStore.getState()
  addMessage(msg)
})

const connect = () => {
  socket.emit("join")
}
let lastposition = {
  x: -9999,
  y: -9999,
  z: -9999
}

const update = (position) => {
  if(position !== undefined && position !== null && (position.x !== lastposition.x || position.y !== lastposition.y || position.z !== lastposition.z)) {
    socket.emit("update", position)
    lastposition = position
  }
  if(internal.startRendering) {
    internal.onupdate()
  }
}

const Sockets = {connect, update, internal}

export default Sockets