import { createRoot } from 'react-dom/client';
import './style/css/index.css';
import UI from './components/UI/ui';
import Sockets from "./sockets/socket"
import SceneManager from "./components/SceneManager/sceneManager"
Sockets.connect()
const Overlay = () => {
  return (
    <>
      <SceneManager />
      <UI/>
    </>
  )
}
const container = document.getElementById('root')
const root = createRoot(container)
root.render(<Overlay/>)