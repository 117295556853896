import { create } from 'zustand'

export const usePlayersStore = create((set) => ({
    players: [],
    setPlayers: (players) => {
      set((state) => ({
        players
      }))
    },
    addPlayer: (player) => {
      set((state) => ({
          players: [
            ...state.players,
            player
          ]
      }))
    },
    updatePlayer: (player) => {
      set((state) => {
        return {
          players: state.players.map((p) => {
            if(p.i === player.i) {
              return player
            }
            return p
          })
        }
      })
    },
    removePlayer: (id) => {
      set((state)=>({
        players: state.players.filter((player) => player.i !== id)
      }))
    }
}))