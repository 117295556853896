import React, { useState, useEffect } from 'react';
import * as THREE from 'three'
import { useTransition, a } from 'react-spring'
import Sockets from "../../sockets/socket"
const Loading = () => {
    const [finished, set] = useState(false)
    const [width, setWidth] = useState(0)
  
    useEffect(() => {
      THREE.DefaultLoadingManager.onLoad = () => {
        set(true)
        Sockets.connect()
      }
      THREE.DefaultLoadingManager.onProgress = (url, itemsLoaded, itemsTotal) =>
        setWidth((itemsLoaded / itemsTotal) * 200)
    }, [])
  
    const props = useTransition(finished, {
      from: { opacity: 1, width: 0 },
      leave: { opacity: 0 },
      update: { width },
    })
  
    return props(({opacity, width}, finished, key)=>
      !finished && (
      <a.div className="loading" key={key} style={{ opacity }}>
      <h1 className="welcome">Meta-Commerce City</h1>   
       <div className="loading-bar-container">                
         <a.div className="loading-bar" style={{ width }} />
       </div>
     </a.div>
    ))
  }

  export default Loading;