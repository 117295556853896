import React from "react";
import Crosshair from "./crosshair"
import { Stats } from "@react-three/drei";
import { useDebug } from "../../stores/useDebug"
import "./debugMenu";
import DebugKeyboard from "./debugKeyboard";
import XboxController from "./xboxController";
import Loading from "./Loading";
import { useMain } from "../../stores/useMain";
import Label from "./Label";
const UI = ({children}) => {
  const { showStats, showKeyboard, showController } = useDebug()
  const { pointerLocked } = useMain()
return (
  <>
    <Loading/>
    <div className="screenContainer" id="screenContainer">
    {!pointerLocked && <Label/>}
    {pointerLocked && <Crosshair/>}
    </div>
    {showStats && <Stats/>}
    {showKeyboard && <DebugKeyboard/>}
    {showController && <XboxController/>}
  </>
)
}
export default UI