import { useGLTF, useTexture } from "@react-three/drei"
import { useEffect, useRef, useState } from "react";
import TrimeshLoader from "./TrimeshLoader";

const Zone3 = ({position = [0,0,0], path}) => {
  const [initialized, setInitialized] = useState(false)
  const [shouldUpdate, setShouldUpdate] = useState(false)
  const hovered = useRef(false)

  const scene = useGLTF(path);
  // scene.materials.White.vertexColors = false
  // scene.materials.Gold.vertexColors = false
  // scene.materials.Black.vertexColors = false
  scene.materials.Black.envMapIntensity = 0.1
  scene.materials.Black.metalness = 0.5
  scene.materials.Black.roughness = 0.4
  scene.materials.Gold.envMapIntensity = 0
  scene.materials.Gold.metalness = 0.7
  // scene.materials.White.emissive.r = 255
  // scene.materials.White.emissive.g = 255
  // scene.materials.White.emissive.b = 255
  scene.materials.Gold.emissive.r = 0.1
  scene.materials.Gold.emissive.g = 0.05
  scene.materials.Gold.emissive.b = 0

  const meshes = useRef([])
  useEffect(()=>{
    if(shouldUpdate || (scene && !initialized)) {
      meshes.current = []
      const keys = Object.keys(scene.nodes)
      // console.dir(scene)
      let pos = [0,0,0]
      keys.forEach((key, idx)=>{
        if(scene.nodes[key].type === "Group") {
          pos = [...scene.nodes[key].position]
          pos[1] = pos[1] - 0.4
        }
        if(scene.nodes[key].type === "Mesh") {
          // console.log(scene.nodes[key])
          meshes.current.push(
            <TrimeshLoader
            position={pos}
            key={scene.nodes[key].name + idx}
            mesh={scene.nodes[key]}
            userData={ hovered.current ? {
              color: "lightblue"
            } : null }
            />
          )
        }
      }) 
      setInitialized(true)
    }
    if(shouldUpdate) {
      setShouldUpdate(false)
    }
  }, [initialized, scene, position, shouldUpdate])
  return (
  <group
    frustumCulled>
    {meshes.current}
    <primitive object={scene.scene} position={[0, 0, 0]}></primitive>
  </group>
  )
}

export default Zone3