import { useRef, useEffect, useState } from "react"
import { useUserInput } from "../../stores/useUserInput"
import "../../style/css/xboxController.css"

const XboxController = () => {
  const ref = useRef()
  const [attached, setAttached] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [X, setX] = useState(null)
  const [Y, setY] = useState(null)
  const {
    mouseX,
    mouseY,
    forward,
    back,
    left,
    right,
    run,
    jump,
    crouch,
    interactAction,
    openSettingsAction
  } = useUserInput()
  useEffect(()=> {
    if(!attached) {
      const domElem = ref.current
      domElem.addEventListener("mousedown", (e)=>{
        if(e.which === 1) {
          setDragging(true)
          setX(e.clientX)
          setY(e.clientY)
        }
      })
      domElem.addEventListener("mouseup", (e)=>{
        if(e.which === 1) {
          setDragging(false)
        }
      })
      setAttached(true)
    }
  },[attached])
  useEffect(()=>{
    if(dragging) {
      const domElem = ref.current
      domElem.style.top = `${mouseY}px`;
      domElem.style.bottom = `initial`;
      domElem.style.left = `${mouseX}px`;
    }
  }, [dragging, mouseX, mouseY, X, Y])
  return (
    <div ref={ref} className="controllerBase">
      <h4>Xbox Controller</h4>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="350"
        fill="none"
        viewBox="0 0 441 383"
      >
        <g>
          <path
            stroke="hsl(210,50%,85%)"
            strokeOpacity="1"
            strokeWidth="3"
            d="M220.5 294.5H150c-45 0-68.5 84-100.5 84S4 363.9 4 317.5s39.5-152 51-180S95.5 92 128 92h92.5"
          ></path>
          <path
            stroke="hsl(210,50%,85%)"
            strokeOpacity="1"
            strokeWidth="3"
            d="M220 294.5h70.5c45 0 68.5 84 100.5 84s45.5-14.6 45.5-61-39.5-152-51-180S345 92 312.5 92H220"
          ></path>
          <circle
            cx="113"
            cy="160"
            r="37.5"
            stroke="hsl(210,50%,85%)"
            strokeOpacity="1"
            strokeWidth="3"
          ></circle>
          <circle
            cx="113"
            cy="160"
            r="28"
            fill="rgba(0,0,0,0)"
            stroke="rgba(0,0,0,1)"
            strokeWidth="3"
          ></circle>
          <circle
            cx="278"
            cy="238"
            r="37.5"
            stroke="hsl(210,50%,85%)"
            strokeOpacity="1"
            strokeWidth="3"
          ></circle>
          <circle
            cx="278"
            cy="238"
            r="28"
            fill="rgba(0,0,0,0)"
            stroke="rgba(0,0,0,1)"
            strokeWidth="3"
          ></circle>
          <circle
            cx="166"
            cy="238"
            r="37.5"
            stroke="hsl(210,50%,85%)"
            strokeOpacity="1"
            strokeWidth="3"
          ></circle>
          <g>
            <mask id="path-8-inside-1" fill="#fff">
              <path d="M177.669 222.335c3.124-3.125 3.147-8.338-.801-10.321a25.006 25.006 0 00-22.439 0c-3.949 1.983-3.926 7.196-.802 10.321l6.364 6.363a7.999 7.999 0 0011.314 0l6.364-6.363z"></path>
            </mask>
            <path
              fill="rgba(0,0,0,0)"
              stroke="rgba(0,0,0,1)"
              strokeWidth="6"
              d="M177.669 222.335c3.124-3.125 3.147-8.338-.801-10.321a25.006 25.006 0 00-22.439 0c-3.949 1.983-3.926 7.196-.802 10.321l6.364 6.363a7.999 7.999 0 0011.314 0l6.364-6.363z"
              mask="url(#path-8-inside-1)"
            ></path>
          </g>
          <g>
            <mask id="path-9-inside-2" fill="#fff">
              <path d="M181.447 249.669c3.124 3.124 8.338 3.147 10.321-.801a24.987 24.987 0 00.755-20.787 25.125 25.125 0 00-.755-1.652c-1.983-3.949-7.197-3.926-10.321-.802l-6.364 6.364a8 8 0 000 11.314l6.364 6.364z"></path>
            </mask>
            <path
              fill="rgba(0,0,0,0)"
              stroke="rgba(0,0,0,1)"
              strokeWidth="6"
              d="M181.447 249.669c3.124 3.124 8.338 3.147 10.321-.801a24.987 24.987 0 00.755-20.787 25.125 25.125 0 00-.755-1.652c-1.983-3.949-7.197-3.926-10.321-.802l-6.364 6.364a8 8 0 000 11.314l6.364 6.364z"
              mask="url(#path-9-inside-2)"
            ></path>
          </g>
          <g>
            <mask id="path-10-inside-3" fill="#fff">
              <path d="M154.113 253.447c-3.124 3.124-3.147 8.338.801 10.32a24.992 24.992 0 0022.439 0c3.948-1.982 3.926-7.196.801-10.32l-6.364-6.364a8 8 0 00-11.313 0l-6.364 6.364z"></path>
            </mask>
            <path
              fill="rgba(0,0,0,0)"
              stroke="rgba(0,0,0,1)"
              strokeWidth="6"
              d="M154.113 253.447c-3.124 3.124-3.147 8.338.801 10.32a24.992 24.992 0 0022.439 0c3.948-1.982 3.926-7.196.801-10.32l-6.364-6.364a8 8 0 00-11.313 0l-6.364 6.364z"
              mask="url(#path-10-inside-3)"
            ></path>
          </g>
          <g>
            <mask id="path-11-inside-4" fill="#fff">
              <path d="M150.335 226.113c-3.125-3.124-8.338-3.147-10.321.801a25.011 25.011 0 000 22.439c1.983 3.948 7.196 3.926 10.321.801l6.363-6.364a7.998 7.998 0 000-11.313l-6.363-6.364z"></path>
            </mask>
            <path
              fill="rgba(0,0,0,0)"
              stroke="rgba(0,0,0,1)"
              strokeWidth="6"
              d="M150.335 226.113c-3.125-3.124-8.338-3.147-10.321.801a25.011 25.011 0 000 22.439c1.983 3.948 7.196 3.926 10.321.801l6.363-6.364a7.998 7.998 0 000-11.313l-6.363-6.364z"
              mask="url(#path-11-inside-4)"
            ></path>
          </g>
          <circle
            cx="329"
            cy="160"
            r="37.5"
            stroke="hsl(210,50%,85%)"
            strokeOpacity="1"
            strokeWidth="3"
          ></circle>
          <g>
            <mask id="path-13-inside-5" fill="#fff">
              <path d="M340.669 144.335c3.124-3.125 3.147-8.338-.801-10.321a25.006 25.006 0 00-22.439 0c-3.949 1.983-3.926 7.196-.802 10.321l6.364 6.363a7.999 7.999 0 0011.314 0l6.364-6.363z"></path>
            </mask>
            <path
              fill="rgba(0,0,0,0)"
              stroke="rgba(0,0,0,1)"
              strokeWidth="6"
              d="M340.669 144.335c3.124-3.125 3.147-8.338-.801-10.321a25.006 25.006 0 00-22.439 0c-3.949 1.983-3.926 7.196-.802 10.321l6.364 6.363a7.999 7.999 0 0011.314 0l6.364-6.363z"
              mask="url(#path-13-inside-5)"
            ></path>
          </g>
          <g>
            <mask id="path-14-inside-6" fill="#fff">
              <path d="M344.447 171.669c3.124 3.124 8.338 3.147 10.321-.801a24.987 24.987 0 00.755-20.787 25.125 25.125 0 00-.755-1.652c-1.983-3.949-7.197-3.926-10.321-.802l-6.364 6.364a8 8 0 000 11.314l6.364 6.364z"></path>
            </mask>
            <path
              fill="rgba(0,0,0,0)"
              stroke="rgba(0,0,0,1)"
              strokeWidth="6"
              d="M344.447 171.669c3.124 3.124 8.338 3.147 10.321-.801a24.987 24.987 0 00.755-20.787 25.125 25.125 0 00-.755-1.652c-1.983-3.949-7.197-3.926-10.321-.802l-6.364 6.364a8 8 0 000 11.314l6.364 6.364z"
              mask="url(#path-14-inside-6)"
            ></path>
          </g>
          <g>
            <mask id="path-15-inside-7" fill="#fff">
              <path d="M317.113 175.447c-3.124 3.124-3.147 8.338.801 10.32a24.992 24.992 0 0022.439 0c3.948-1.982 3.926-7.196.801-10.32l-6.364-6.364a8 8 0 00-11.313 0l-6.364 6.364z"></path>
            </mask>
            <path
              fill="rgba(0,0,0,0)"
              stroke="rgba(0,0,0,1)"
              strokeWidth="6"
              d="M317.113 175.447c-3.124 3.124-3.147 8.338.801 10.32a24.992 24.992 0 0022.439 0c3.948-1.982 3.926-7.196.801-10.32l-6.364-6.364a8 8 0 00-11.313 0l-6.364 6.364z"
              mask="url(#path-15-inside-7)"
            ></path>
          </g>
          <g>
            <mask id="path-16-inside-8" fill="#fff">
              <path d="M313.335 148.113c-3.125-3.124-8.338-3.147-10.321.801a25.015 25.015 0 00-.756 20.787c.233.56.485 1.111.756 1.652 1.983 3.948 7.196 3.926 10.321.801l6.363-6.364a7.998 7.998 0 000-11.313l-6.363-6.364z"></path>
            </mask>
            <path
              fill="rgba(0,0,0,0)"
              stroke="rgba(0,0,0,1)"
              strokeWidth="6"
              d="M313.335 148.113c-3.125-3.124-8.338-3.147-10.321.801a25.015 25.015 0 00-.756 20.787c.233.56.485 1.111.756 1.652 1.983 3.948 7.196 3.926 10.321.801l6.363-6.364a7.998 7.998 0 000-11.313l-6.363-6.364z"
              mask="url(#path-16-inside-8)"
            ></path>
          </g>
          <circle
            cx="185"
            cy="162"
            r="10"
            fill="rgba(0,0,0,0)"
            stroke="rgba(0,0,0,1)"
            strokeWidth="3"
          ></circle>
          <circle
            cx="259"
            cy="162"
            r="10"
            fill="rgba(0,0,0,0)"
            stroke="rgba(0,0,0,1)"
            strokeWidth="3"
          ></circle>
          <rect
            width="41"
            height="13"
            x="111.5"
            y="61.5"
            fill="rgba(0,0,0,0)"
            stroke="rgba(0,0,0,1)"
            strokeWidth="3"
            rx="6.5"
          ></rect>
          <rect
            width="41"
            height="13"
            x="289.5"
            y="61.5"
            fill="rgba(0,0,0,0)"
            stroke="rgba(0,0,0,1)"
            strokeWidth="3"
            rx="6.5"
          ></rect>
          <path
            fill="rgba(0,0,0,0)"
            stroke="rgba(0,0,0,1)"
            strokeWidth="3"
            d="M152.5 37a7.5 7.5 0 01-7.5 7.5h-13a7.5 7.5 0 01-7.5-7.5V16.5c0-7.732 6.268-14 14-14s14 6.268 14 14V37z"
          ></path>
          <path
            fill="rgba(0,0,0,0)"
            stroke="rgba(0,0,0,1)"
            strokeWidth="3"
            d="M317.5 37a7.5 7.5 0 01-7.5 7.5h-13a7.5 7.5 0 01-7.5-7.5V16.5c0-7.732 6.268-14 14-14s14 6.268 14 14V37z"
          ></path>
          <path
            stroke="hsl(210,50%,85%)"
            strokeWidth="3"
            d="M30 210L130 300"
            opacity="0.3"
          ></path>
          <path
            stroke="hsl(210,50%,85%)"
            strokeWidth="3"
            d="M411 210L311 300"
            opacity="0.3"
          ></path>
        </g>
      </svg>
    </div>
  );
}

export default XboxController;
