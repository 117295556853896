import { useTexture } from "@react-three/drei";
import { DoubleSide, RepeatWrapping } from "three";
const x = -Math.PI / 2
export const Ground = () => {
  const url ="textures/ground/greybricks.jpg"
  const texture = useTexture(url, (texture) => {
    texture.wrapS = texture.wrapT = RepeatWrapping
    texture.repeat.set(5000,5000)
    texture.rotation = 1
  })
  return (
    <mesh
      rotation={[x, 0, 10]}
      position={[0, 0, 0]}
      receiveShadow>
      <planeGeometry
        attach="geometry"
        args={[5000, 5000, 1]} />
      <meshStandardMaterial map={texture}></meshStandardMaterial>
    </mesh>
  )
}
