import React from "react";
import { Environment } from "@react-three/drei";

export const Skybox = () => {
  return <Environment
  background
  files={[
    "./textures/skies/day/left.jpg",
    "./textures/skies/day/right.jpg",
    "./textures/skies/day/top.jpg",
    "./textures/skies/day/bottom.jpg",
    "./textures/skies/day/back.jpg",
    "./textures/skies/day/front.jpg"
  ]}>
    
  </Environment>;
};
