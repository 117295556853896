import { Physics } from "@react-three/cannon";
import { Plane } from "../prefabs/Plane";
import { Ground } from "../prefabs/Ground"
import { Skybox } from "../prefabs/Skybox";
import PhysicsDebugger from "../components/PhysicsDebugger/debug";
import { useMain } from "../stores/useMain"
import { Suspense, useEffect } from "react";
import { useThree } from "@react-three/fiber";
import { PlayerController } from "../prefabs/PlayerController";
import Placeholders from "../prefabs/Placeholders";
import Presentation from "../prefabs/Presentation";
import PresentationPhysics from "../prefabs/PresentationPhysics";
import Tablet from "../prefabs/Tablet";
const Scene = (props) => {
  const { gravity } = useMain()
  const { camera } = useThree()
  useEffect(() => {
    camera.layers.enable(0);
    camera.layers.enable(1);
  }, [camera]);
  return (
    <Physics
      isPaused={false}
      allowSleep={false}
      gravity={gravity}
      tolerance={0.001}
      iterations={5}
      size={1000}
      stepSize={1/60}
      maxSubSteps={10}
      solver={'Split'}
      broadphase={"Naive"}
    >
      <PhysicsDebugger>
        <Skybox/>
        <directionalLight 
          intensity={0.5} 
          position={[0, 50, 0]}
        />
        <pointLight 
          intensity={0.6} 
          position={[0, 80, 13]}
          distance={0}
        /> 
        <ambientLight intensity={0.3}/>
        <pointLight 
          intensity={0.2} 
          position={[0, 10, 10]}
          distance={0}
        /> 
        <pointLight 
          intensity={0.2} 
          position={[0, 5, -2.6660127805658738]}
          distance={0}
        /> 
        <PlayerController position={[3,5,0]}/>
        <Placeholders></Placeholders>
        <Plane></Plane>
        <Suspense>
          <Ground></Ground>
          {/* <CityLoader/>
          <Zone2 path="/assets/3D/Cities/z2/z2.gltf"/>
          <Zone3 path="/assets/3D/Cities/z3/z3.gltf"/> */}
          <Presentation path="/assets/3D/Cities/Presentation/City.gltf"/>
          <PresentationPhysics path="/assets/3D/Cities/PresentationPhysics/Physics.gltf"/>
          <Tablet path="/assets/3D/Cities/Tablet/tablet.gltf"/>
        </Suspense>
      </PhysicsDebugger>
    </Physics>
  )
}

export default Scene