import {DoubleSide} from "three"
import { useTrimesh } from "@react-three/cannon";

const TrimeshLoader = ({scale, position, mesh, userData}) => {
  const defaultColor = mesh.material.color || "white"
  const [ref] = useTrimesh(() => (
  {
    type: "Static",
    mass: 200000,
    material: {
      friction: 0
    },
    position: position,
    scale: 0.5,
    args: [
      mesh.geometry.attributes.position.array,
      mesh.geometry.index.array
    ],
  }
  ));
  return (
    <></>
  )
}

export default TrimeshLoader