import { useGLTF, useTexture } from "@react-three/drei"
import { useEffect, useRef, useState } from "react";
import TrimeshLoader from "./TrimeshLoader";

const Zone3 = ({position = [0,0,0], path}) => {
  const scene = useGLTF(path);
  const opaqueMaterial = scene.materials["Opaque Material"]
  opaqueMaterial.transparent = true
  opaqueMaterial.opacity = 0.4
  const glass = scene.materials.glass
  glass.envMapIntensity = 4
  const meshes = useRef([])
  
  return (
  <group
    frustumCulled>
    {meshes.current}
    <primitive object={scene.scene} position={[0, 0, 0]}></primitive>
  </group>
  )
}

export default Zone3