import { create } from 'zustand'
import { useMain } from './useMain'
export const useUserInput = create((set) => ({
  gamepad: undefined,
  keysDown: 0,
  gamepadInterval: null,
  gamepadLookX: 0,
  gamepadLookY: 0,
  forward: false,
  back: false,
  left: false,
  right: false,
  jump: false,
  run: false,
  crouch: false,
  primaryAction: false,
  secondaryAction: false,
  interactAction: false,
  openSettingsAction: false,
  openDebugMenu: false,
  mouseX: 0,
  mouseY: 0
}))

const keysMap = {
  forward: ['w', 'arrowup', 'pU'],
  back: ['s', 'arrowdown', 'pD'],
  left: ['a', 'arrowleft', 'pL'],
  right: ['d', 'arrowright', 'pR'],
  jump: [' ', 'pA'],
  run: ['shift', 'pRun'],
  crouch: ['c'],
  primaryAction: ["mouse1", "pX"],
  secondaryAction: ["mouse3", "pY"],
  interactAction: ["e", "pRb"],
  openSettingsAction: ["escape", "pMenu"],
  openDebugMenu: []
}
function actionExists(input) {
  const actions = Object.keys(keysMap)
  return actions.find(a => {
    return keysMap[a].includes(input)
  })
}
export const attachUserInputListeners = () => {
  document.addEventListener('keydown', (keydown) => {
    const key = keydown.key.toLowerCase()
    const action = actionExists(key)
    if(action) {
      const state = useUserInput.getState()
      const stateAction = state[action]
      if(!stateAction) {
        useUserInput.setState({[action]: true, keysDown: state.keysDown+1})
      }
    }
  })
  document.addEventListener('keyup', (keyup) => {
    const key = keyup.key.toLowerCase()
    const action = actionExists(key)
    if(action) {
      const state = useUserInput.getState()
      const stateAction = state[action]
      if(stateAction) {
        useUserInput.setState({[action]: false, keysDown: state.keysDown-1})
      }
    }
  })
  document.addEventListener('mousemove', (e) => {
    useUserInput.setState({
      mouseX: e.clientX,
      mouseY: e.clientY
    })
  })
  // Gamepad Handling
  function handleGamepadInput() {
    if(useMain.getState().pointerLocked) {
      const state = useUserInput.getState()
      const gamepadIndex = state.gamepad
      const gamepad = navigator.getGamepads()[gamepadIndex]
      const buttons = gamepad.buttons
      const axes = gamepad.axes
      const absX = Math.abs(axes[0])
      const absY = Math.abs(axes[1])
      const driftPrevention = 0.2
      const runThreshold = 0.8
      const xboxMap = {
        pD: axes[1] >  driftPrevention,
        pU: axes[1] < -driftPrevention,
        pL: axes[0] < -driftPrevention,
        pR: axes[0] >  driftPrevention,
        pRun: (absX > runThreshold || absY > runThreshold),
        pA: buttons[0].pressed,
        pB: buttons[1].pressed,
        pX: buttons[2].pressed,
        pY: buttons[3].pressed,
      }
      const result = {
        ...xboxMap
      }
      const resultKeys = Object.keys(result)
      const stateChanges = {}
      resultKeys.forEach(o=>{
        const exists = actionExists(o)
        if(exists) {
          if(result[o] !== state[exists]) {
            stateChanges[exists] = result[o]
          }
        }
      })
      if(axes[2] !== state.gamepadLookX) {
        stateChanges.gamepadLookX = axes[2]
      }
      if(axes[3] !== state.gamepadLookY) {
        stateChanges.gamepadLookY = axes[3]
      }
      if(Object.keys(stateChanges).length > 0) {
        if(useUserInput.getState().keysDown === 0) {
          useUserInput.setState({...stateChanges})
        }
      }
      
    }
  }
  window.addEventListener("gamepadconnected", (e) => {
    console.log(
      "Gamepad connected at index %d: %s. %d buttons, %d axes.",
      e.gamepad.index,
      e.gamepad.id,
      e.gamepad.buttons.length,
      e.gamepad.axes.length
    );
    const exists = useUserInput.getState().gamepad
    if(!exists) {
      const interval = setInterval(handleGamepadInput, 100)
      useUserInput.setState({gamepad: e.gamepad.index, gamepadInterval: interval})
    }

  });
  window.addEventListener("gamepaddisconnected", (e) => {
    const gamepad = useUserInput.getState().gamepad
    if(gamepad !== undefined && gamepad === e.gamepad.index) {
      const interval = useUserInput.getState().gamepadInterval
      clearInterval(interval)
      useUserInput.setState({gamepad: undefined, gamepadInterval: undefined})
    }
  });
}