import { create } from 'zustand'

const usePlayerController = create((set) => ({
  speed: 5,
  runSpeed: 7,
  jumpHeight: 5,
  mass: 70,
  jumpCooldown: 400,
  x: 0,
  y: 1,
  z: 0,
}))

export default usePlayerController