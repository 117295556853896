import { Canvas, useThree } from "@react-three/fiber"
import { PointerLockControls } from "@react-three/drei"
import DefaultScene from "../../scenes/defaultScene"
import { useMain } from "../../stores/useMain"
import { attachUserInputListeners, useUserInput } from "../../stores/useUserInput"
import { Suspense, useEffect, useRef } from "react"
import { Vector3, Quaternion } from "three"
import Controls from "./controls"


export default function App() {
  return (
    <Canvas id="mainAppCanvas" shadows="soft">
      <Suspense fallback={null}>
        <Controls/>
        <DefaultScene/>
      </Suspense>
    </Canvas>
  )
}