import { useMain } from "../../stores/useMain"
import { attachUserInputListeners, useUserInput } from "../../stores/useUserInput"
import { useEffect, useRef } from "react"
import { Vector3, Quaternion } from "three"
import { PointerLockControls } from "@react-three/drei"
import { useThree } from "@react-three/fiber"

const fakeMouseMove = new PointerEvent("pointermove", {
  bubbles: true,
  cancelable: true,
  view: window,
});

function getForwardVectorFromQuaternion(quaternion) {
  var q = new Quaternion().copy(quaternion).normalize();
  var v = new Vector3(0, 0, -1);
  v.applyQuaternion(q);
  return v;
}
attachUserInputListeners()

const Controls = () => {
  const { unlockPointer, lockPointer } = useMain()
  const setEvents = useThree((state) => state.setEvents)
  const pcl = useRef()
  useEffect(()=>{
    setEvents({
      compute(event, state) {
        state.pointer.set(0, 0)
        state.raycaster.setFromCamera(state.pointer, state.camera)
      },
    })
    setInterval(()=>{
      if(pcl.current) {
        const {gamepadLookX, gamepadLookY} = useUserInput.getState()
        pcl.current.camera.rotateOnWorldAxis({x:0,y:1,z:0}, 0.01 * -gamepadLookX)
        const input = new Quaternion()
        input.setFromAxisAngle({x: 1, y: 0, z: 0}, 0.01 * -gamepadLookY)
        const rotation = input.clone()
        input.multiply(pcl.current.camera.quaternion)
        let y = getForwardVectorFromQuaternion(input).y
        y = Number(y.toFixed(2))
        if(y > -0.95 && y < 0.95) {
          pcl.current.camera.quaternion.multiply(rotation)
        }
        if(y <= -0.95 && gamepadLookY < 0) {
          pcl.current.camera.rotateOnAxis({x:1,y:0,z:0}, 0.013)
        }
        if(y >= 0.95 && gamepadLookY > 0) {
          pcl.current.camera.rotateOnAxis({x:1,y:0,z:0}, -0.013)
        }
        pcl.current.domElement.dispatchEvent(fakeMouseMove)
      }
    }, 5)
  },[])
  return <>
  <PointerLockControls  ref={pcl} selector="#screenContainer" onUnlock={unlockPointer} onLock={lockPointer} makeDefault/>
  </>
}

export default Controls