import { GUI } from 'dat.gui'
import { useMain } from "../../stores/useMain"
import { useDebug } from "../../stores/useDebug"

const {
  showDebugMenu,
  togglePhysicsWireframes,
  toggleShowKeyboard,
  toggleShowController,
  showStats,
  toggleStats } = useDebug.getState()
if(showDebugMenu) {
const { gravity, setGravity } = useMain.getState()
window.$setGravity = setGravity
const gui = new GUI({name: "Debug Menu", autoPlace: false})
gui.domElement.style.display = showDebugMenu ? "" : "none"
gui.domElement.id="debugGUI"
const statisticsFolder = gui.addFolder('Statistics')
const stats = statisticsFolder.add({"Show Stats": showStats}, "Show Stats")
const userInputFolder = gui.addFolder('User Input')
const userInputKeyboard = userInputFolder.add({"Show Keyboard": false}, "Show Keyboard")
const userInputController = userInputFolder.add({"Show Controller": false}, "Show Controller")
const physicsFolder = gui.addFolder('Physics')
const physicsWireframes = physicsFolder.add({"Show Wireframes": false}, "Show Wireframes")
const x = physicsFolder.add({X: gravity[0]}, "X", -10.0, 10.0, 0.1)
const y = physicsFolder.add({Y: gravity[1]}, "Y", -10.0, 10.0, 0.1)
const z = physicsFolder.add({Z: gravity[2]}, "Z", -10.0, 10.0, 0.1)
x.onFinishChange((v)=>{setGravity(v, gravity[1], gravity[2])})
y.onFinishChange((v)=>{setGravity(gravity[0], v, gravity[2])})
z.onFinishChange((v)=>{setGravity(gravity[0], gravity[1], v)})
stats.onChange(()=>{toggleStats()})
physicsWireframes.onChange(()=>{togglePhysicsWireframes()})
userInputKeyboard.onChange(()=>{toggleShowKeyboard()})
userInputController.onChange(()=>{toggleShowController()})
useMain.subscribe(e => {
  x.setValue(e.gravity[0])
  y.setValue(e.gravity[1])
  z.setValue(e.gravity[2])
})
useDebug.subscribe(e => {
  if(e.showDebugMenu) {
    gui.domElement.style.display = ""
  } else {
    gui.domElement.style.display = "none"
  }
})
document.body.appendChild(gui.domElement)
}