import { create } from 'zustand'

const maxMessages = 50

export const useMessageStore = create((set) => ({
    messages: [],
    addMessage: (message) => {
      set((state) => {
        let resultingMessages = [...state.messages]
        if(resultingMessages.length === maxMessages) {
          resultingMessages.shift()
        }
        return {
          messages: [
            ...resultingMessages,
            {
              type: message.type,
              from: message.from,
              to: message.to,
              message: message.message
            }
          ]
        }
      })
    },
}))