import React, { Suspense, useRef } from 'react';
import { Text } from "@react-three/drei"
import { Vector3 } from 'three';
import { useFrame, useThree } from '@react-three/fiber';
const Placeholder = ({
  position,
  name
}) => {
    const textPosition = [...position]
    textPosition[1] += 0.7
    const camera = useThree(state => state.camera)
    const textRef = useRef()
    useFrame(() => {
      if(textRef && textRef.current) {
        const camPos = camera.position
        const lookedAt = new Vector3(camPos.x, textRef.current.position.y, camPos.z)
        textRef.current.lookAt(lookedAt)
      }
    })
    return (  
            <>
              <mesh visible userData={{ test: "hello" }} position={position} castShadow>
                <sphereGeometry attach="geometry" args={[0.51, 32, 32]} />
                <meshStandardMaterial
                  attach="material"
                  color="white"
                  transparent
                  roughness={0.1}
                  metalness={0.1}
                />
              </mesh>
              <Suspense fallback={null}>
                <Text
                  color={"black"}
                  fontSize={0.1}
                  lineHeight={1}
                  letterSpacing={0.02}
                  textAlign={'left'}
                  font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
                  position={textPosition}
                  ref={textRef}
                >
                  {"Guest"}
                </Text>
              </Suspense>
            </> 
    );
}

export default Placeholder;